<script lang="ts">
export interface VImgCarousel2Props {
  images: string[]
}
</script>

<script setup lang="ts">
const props = withDefaults(defineProps<VImgCarousel2Props>(), {

})

const currentIndex = ref(0)

function dec() {
  currentIndex.value--
}

function inc() {
  currentIndex.value++
}

const RENDERED_IMAGE_COUNT = 5

const renderedImages = computed(() => {
  const arrayLength = props.images.length
  const images: string[] = []

  // eslint-disable-next-line no-unmodified-loop-condition
  for (let i = 0; i < RENDERED_IMAGE_COUNT; i++) {
    const index = currentIndex.value + i - (Math.floor(RENDERED_IMAGE_COUNT / 2))
    const cyclicIndex = ((index % arrayLength) + arrayLength) % arrayLength
    images.push(props.images[cyclicIndex])
  }

  return images
})
</script>

<template>
  <div class="relative h-xl flex flex-row items-center justify-between compact-container">
    <div class="z-50 h-12 w-12 rounded-full bg-white p-1 md:-translate-x-20">
      <button class="i-mdi:chevron-triple-left h-full w-full text-void" @click="dec" />
    </div>
    <div class="z-50 h-12 w-12 rounded-full bg-white p-1 md:translate-x-20">
      <button class="i-mdi:chevron-triple-right h-full w-full text-void" @click="inc" />
    </div>

    <div class="absolute inset-0 h-full w-full flex flex-col items-center justify-center overflow-hidden text-void">
      <div class="w-full -translate-x-100">
        <div v-auto-animate class="w-full flex flex-row items-center justify-between gap-5">
          <NuxtImg
            v-for="(image) in renderedImages"
            :key="image"
            :alt="`Carousel Image ${image}`"
            class="h-100 w-100 rounded"
            :src="image"
          />
        </div>
      </div>
    </div>
  </div>
</template>
